import { mapState } from 'vuex';
import { getSignupList, signupOperate } from '@/service/enroll-activity/index';
import { getToken } from "@/utils/localStorage.js";

// 状态值: 10 待付款;20 待审核;30 取消待审核;40 已通过;50 已拒绝;60 已取消;
const stateIcon = {
    10: 'add-s-1.png',
    20: 'add-s-4.png',
    30: 'add-s-6.png',
    40: 'add-s-3.png',
    50: 'add-s-5.png',
    60: 'add-s-2.png'
}
const stateBtn = {
    10: 'btn1',
    20: 'btn1',
    30: 'btn1',
    40: 'btn2',
    // 50: '',
    60: 'btn3'
}
// 
const stateOperate = [20, 40]
export default {
    data() {
        return {
            stateIcon,
            stateBtn,
            loading: true,
            finished: false,
            list: [],
            keyword: '',
            offset: 1,
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        ...mapState({
            userId: state => state.user.user.id
        }),
    },
    methods: {
        // 参数化
        init(){
            this.list = []
            this.offset = 1
            this.getSList()
        },
        // 获取列表数据
        async getSList() {
            let { offset, userId, keyword, list } = this;

            const params = {
                signupId: this.$route.query.id,
                limit: 10,
                curPage: offset,
                keyword,
                userId
            }
            const res = await getSignupList(params)

            // 处理航班时间
            const handleList = res.records.map(item => {
                item.goDepartureTime = this.handleTime(item.goDepartureTime)
                item.goArrivalTime = this.handleTime(item.goArrivalTime)
                item.backDepartureTime = this.handleTime(item.backDepartureTime)
                return item
            })

            this.loading = false
            this.list = [...list, ...handleList]
            if (handleList.length < 10) this.finished = true
        },
        // 处理时间
        handleTime(t) {
            if(t === null){
                return '';
            }else{
            let date = t.split(' ')[0].split('-')
            let time = t.split(' ')[1].split(':')

            let d = `${date[0]}年${date[1]}月${date[2]}日`
            let ts = `${time[0]}:${time[1]}`

            return d + ' ' + ts
            }
        },
        // 搜索
        onInputSearch() {
            this.offset = 1;
            this.list = []
            this.getSList()
        },
        // 加载
        onLoad() {
            this.offset++
            this.getSList()
        },
        // 跳转报名
        onGoEnroll() {
            this.$router.push({
                name: 'write-enroll',
                query: {
                    signupId: this.$route.query.signupId
                }
            })
        },
        // 点击取消按钮
         onOperate(row) {
            if(!stateOperate.includes(row.state)) return
            let { userId, list } = this;

            this.$dialog.confirm({
                title: '提示',
                message: '确定操作？'
            }).then(async () => {
                const params = {
                    operate: 'CANCEL',
                    userId,
                    id: row.id,
                    state: row.state,
                    token: getToken()
                }
                await signupOperate(params)
                this.$toast('操作成功')
         
                list.forEach(item =>{
                    if(item.id == row.id) {
                        if(item.state == 40) item.state = 30
                        else item.state = 60
                    }
                })
                this.list = [...list]
            })
        },
        // 点击付款
        onWxPay(row){
            this.$router.push({ 
                name: 'enroll-wxPay', 
                query: { 
                    amount: row.actPrice,
                    name: row.name,
                    identity: row.identity,
                    tourismPackages: row.productName,
                    code: row.code,
                    from: 'add-enroll'
                }
            })
        }
    }
}